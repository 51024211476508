import React, { useEffect, useMemo } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';
import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSuperHubStore } from '..';

import { mockVersions, type ReadVersionCollectionType } from './mock';

export interface InitializeSuperHubVersionsProps {
  children: React.ReactNode;

  /**
   * Initial versions data, typically sourced by SSR props generated by our
   * express controllers.
   */
  initialVersions?: ReadVersionCollectionType;
}

/**
 * Initializes the SuperHubStore's version slice with initial data and connects
 * it to the git versions API endpoint to continually keep it in sync.
 */
export function InitializeSuperHubVersions({ children, initialVersions }: InitializeSuperHubVersionsProps) {
  const { isServer } = useEnvInfo();
  const [apiBaseUrl, initialize, isReady] = useSuperHubStore(s => [
    s.apiBaseUrlWithoutVersion,
    s.versions.initialize,
    s.versions.isReady,
    s.isSuperHubManageVersionsEnabled,
  ]);

  /**
   * Temporary stub of versions data until API endpoints are live.
   * @todo remove these once `/versions` is available.
   */
  const fakeVersions = useMemo(() => mockVersions(10), []);

  // PART 1: initialize our store during SSR and first CSR render if/when we
  // have initial versions data included in our SSR props generated by our
  // express controller. We must call this inline rather than inside an effect.
  if (isServer || !isReady) {
    initialize({
      data: initialVersions || fakeVersions,
      error: null,
      isLoading: false,
    });
  }

  // PART 2: Connect to API via SWR to keep it in sync. This will continually
  // update our store with the API response in an async way.
  const {
    data = initialVersions || fakeVersions,
    error = null,
    isLoading,
    swrKey,
  } = useReadmeApi<ReadVersionCollectionType>('/versions', {
    apiBaseUrl,
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });

  useEffect(() => {
    initialize({ data, error, isLoading, swrKey });
  }, [data, error, initialize, isLoading, swrKey]);

  return <>{children}</>;
}
