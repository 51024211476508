import { superHubStore } from '..';

/** Global counter to ensure factory-made versions stay unique. */
let mockVersionCount = 0;

/**
 * Paginated collection representation for project versions.
 * @todo Replace with API v2 representation when ready.
 */
export interface ReadVersionCollectionType {
  data: ReadVersionType['data'][];
  per_page: number;
  total: number;
}

/**
 * Read representation for a project version.
 * @todo Replace with API v2 representation when ready.
 */
export interface ReadVersionType {
  data: {
    base: string;
    display_name?: string;
    name: string;
    release_stage: 'beta' | 'release';
    state: 'current' | 'deprecated';
    updated_at: string;
    uri: string;
    visibility: 'default' | 'hidden' | 'public';
  };
}

/**
 * Create representation for a project version.
 * @todo Replace with API v2 representation when ready.
 */
export interface CreateVersionType {
  base: string;
  display_name?: string;
  name: string;
  release_stage?: 'beta' | 'release';
  state?: 'current' | 'deprecated';
  visibility?: 'default' | 'hidden' | 'public';
}

/**
 * Returns a mock version that can be used for testing.
 */
export function mockVersion(keys: Partial<ReadVersionType['data']> = {}): ReadVersionType['data'] {
  mockVersionCount += 1;
  const defaultVersion = superHubStore.getState().versions.defaultVersion;
  const name = `${mockVersionCount}.0`;
  return {
    base: '1.0',
    name,
    release_stage: 'release',
    state: 'current',
    updated_at: new Date().toISOString(),
    uri: `/versions/${name}`,
    visibility: defaultVersion === name ? 'default' : 'hidden',
    ...keys,
  };
}

/**
 * Returns a mock collection of versions that can be used for testing. Generates
 * `n` number of mock versions based on the count provided.
 */
export function mockVersions(count: number): ReadVersionCollectionType {
  const versions = new Array(count).fill(null).map(() => mockVersion());
  return {
    data: versions,
    per_page: versions.length,
    total: versions.length,
  };
}
